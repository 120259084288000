const visitMutation = {
  setVisits (state, getVisit) {
    state.visits = getVisit[0]
    state.visitsSize = getVisit[1]
    state.paginateOptions = getVisit[2]
  },
  addVisit (state, visit) {
    state.visits = [...state.visits, visit]
  },
  updateVisit(state, newVisit) {
    state.visits = state.visits.map((visit) => {
      if(visit.id === newVisit.id)
        return {...visit, ...newVisit}
      return visit
    })
  },
  deleteVisit(state, deleteVisit){
    state.visits = state.visits.filter((visit)=>{
      return visit.id !== deleteVisit;
    })
  },
  setVisit (state, getVisit) {
    state.visit = getVisit
  },
  // visits.forEach((visit)=>{

  // })
  // visits.findIndex()
}

export default visitMutation;