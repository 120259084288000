const pet_ownerMutation = {
  setPetOwners (state, getPetOwner) {
    state.pet_owners = getPetOwner[0]
    state.pet_ownersSize = getPetOwner[1]
    state.paginateOptions = getPetOwner[2]
  },
  addPetOwner (state, pet_owner) {
    state.pet_owners = [...state.pet_owners, pet_owner]
  },
  updatePetOwner(state, newPetOwner) {
    state.pet_owners = state.pet_owners.map((pet_owner) => {
      if(pet_owner.id === newPetOwner.id)
        return {...pet_owner, ...newPetOwner}
      return pet_owner
    })
  },
  deletePetOwner(state, deletePetOwner){
    state.pet_owners = state.pet_owners.filter((pet_owner)=>{
      return pet_owner.id !== deletePetOwner;
    })
  },
  setPetOwner (state, getPetOwner) {
    state.pet_owner = getPetOwner
  },
  // pet_owners.forEach((pet_owner)=>{

  // })
  // pet_owners.findIndex()
}

export default pet_ownerMutation;