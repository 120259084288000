import { deserialize } from 'jsonapi-deserializer'
import {payment} from '../../api'
import apiRoutes from '../../api/api_routes'
import axios from 'axios'

const paymentActions = {
  newPaymentForm ({commit}, {newPayment, cb}) {
    payment.newPaymentForm(newPayment, (resp) => {
      commit('addPayment', {...newPayment, id: 1});
      cb(resp);
    })
  },
  updatePaymentForm ({commit}, {updatePayment, cb}) {
    payment.updatePaymentForm(updatePayment, (resp) => {
      commit('updatePayment', {...updatePayment, id: 1});
      cb(resp);
    })
  },
  getAllPayments ({commit}, {params, cb}) {
    payment.getAllPayments(params, (resp) => {
      console.log("getAllPayments>>>>>",resp.data)
      commit('setPayments', [resp.data, resp.total_count, resp.meta])
      cb(resp)
    })
  },
  deletePayment ({commit, state}, payment_id) {
    axios.delete( apiRoutes.payment + '/' + payment_id)
    .then(() => {
      commit('deletePayment', {...state, payment_id})
    })
    .catch(e => {
      console.log("!!!!!")
    })
  },
  findPayment ({commit}, {findPayment, cb}) {
    payment.findPayment(findPayment, (resp) => {
      commit('setPayment', {...findPayment});
      cb(resp);
    })
  }
}

export default paymentActions;