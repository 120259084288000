import { deserialize } from 'jsonapi-deserializer'
import {doctor_service} from '../../api'
import apiRoutes from '../../api/api_routes'
import axios from 'axios'

const doctor_serviceActions = {
  newDoctorServiceForm ({commit}, {newDoctorService, cb, errcb}) {
    doctor_service.newDoctorServiceForm(newDoctorService, (resp) => {
      commit('addDoctorService', {...newDoctorService, id: 1});
      cb(resp);
    }, ()=>errcb())
  },
  updateDoctorServiceForm ({commit}, {updateDoctorService, cb}) {
    doctor_service.updateDoctorServiceForm(updateDoctorService, (resp) => {
      commit('updateDoctorService', {...updateDoctorService, id: 1});
      cb(resp);
    })
  },
  getAllDoctorServices ({commit}, {params, cb}) {
    doctor_service.getAllDoctorServices(params, (resp) => {
      console.log("getAllDoctorServices>>>>>",resp.data)
      commit('setDoctorServices', [resp.data, resp.total_count, resp.meta])
      cb(resp)
    })
  },
  deleteDoctorService ({commit, state}, doctor_service_id) {
    axios.delete( apiRoutes.doctor_service + '/' + doctor_service_id)
    .then(() => {
      commit('deleteDoctorService', doctor_service_id)
    })
    .catch(e => {
      console.log("!!!!!")
    })
  },
  findDoctorService ({commit}, {findDoctorService, cb}) {
    doctor_service.findDoctorService(findDoctorService, (resp) => {
      commit('setDoctorService', {...findDoctorService});
      cb(resp);
    })
  }
}

export default doctor_serviceActions;