import apiRoutes from './api_routes'
// import { securedAxiosrInstance, plainAxiosInstance } from './index'
import axios from 'axios'
import { deserialize } from 'jsonapi-deserializer'

const pet = {
	newPetForm (newPet, cb, errcb) {
    axios.post(apiRoutes.pet, newPet)
      .then(() => {
        cb({result: "OK"})
      })
      .catch((err) => {
        console.log(err)
        errcb({result: "ERROR"})
      })
  },

  updatePetForm (updatePet, cb, errcb) {
    axios.put(apiRoutes.pet + '/' + updatePet[0], updatePet[1])
      .then(() => {
        cb({result: "OK"})
      })
      .catch(e => {
        errcb({result: "ERROR"})
      })
  },

  findPet (findPet, cb, errcb) {
    axios.get(apiRoutes.pet + '/' + findPet)
      .then(response => {        
        cb({result: "OK", data: deserialize(response.data)})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },

  getAllPets (params, cb, errcb) {
    axios.get(apiRoutes.pet + params)
      .then(response => {
        console.log("111>>>", deserialize(response.data))
        cb({result: "OK", data: deserialize(response.data), total_count: response.data.meta.total_count, meta: response.data.meta})
      })
      .catch((e) => {
        console.log(e)
        errcb({result: "ERROR"})
      })
  }
}

export default pet;