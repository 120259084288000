const clinicMutation = {
  setClinics (state, getClinic) {
    state.clinics = getClinic[0]
    state.clinicsSize = getClinic[1]
    state.paginateOptions = getClinic[2]
  },
  addClinic (state, clinic) {
    state.clinics = [...state.clinics, clinic]
  },
  updateClinic(state, newClinic) {
    state.clinics = state.clinics.map((clinic) => {
      if(clinic.id === newClinic.id)
        return {...clinic, ...newClinic}
      return clinic
    })
  },
  deleteClinic(state, deleteClinic){
    state.clinics = state.clinics.filter((clinic)=>{
      return clinic.id !== deleteClinic;
    })
  },
  setClinic (state, getClinic) {
    state.clinic = getClinic
  },
  // clinics.forEach((clinic)=>{

  // })
  // clinics.findIndex()
}

export default clinicMutation;