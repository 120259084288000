import { deserialize } from 'jsonapi-deserializer'
import {doctor} from '../../api'
import apiRoutes from '../../api/api_routes'
import axios from 'axios'

const doctorActions = {
  newDoctorForm ({commit}, {newDoctor, cb}) {
    doctor.newDoctorForm(newDoctor, (resp) => {
      commit('addDoctor', {...newDoctor, id: 1});
      cb(resp);
    })
  },
  updateDoctorForm ({commit}, {updateDoctor, cb}) {
    doctor.updateDoctorForm(updateDoctor, (resp) => {
      commit('updateDoctor', {...updateDoctor, id: 1});
      cb(resp);
    })
  },
  getAllDoctors ({commit}, {params, cb}) {
    doctor.getAllDoctors(params, (resp) => {
      console.log("getAllDoctors>>>>>",resp.data)
      commit('setDoctors', [resp.data, resp.total_count, resp.meta])
      cb(resp)
    })
  },
  deleteDoctor ({commit, state}, {deleteDoctor, cb}) {
    axios.delete( apiRoutes.doctor + '/' + deleteDoctor)
    .then(() => {
      commit('deleteDoctor', deleteDoctor)
      cb({result: "OK"})
    })
    .catch(e => {
      console.log("!!!!!")
    })
  },
  findDoctor ({commit}, {findDoctor, cb}) {
    doctor.findDoctor(findDoctor, (resp) => {
      commit('setDoctor', {...findDoctor});
      cb(resp);
    })
  },
  getConnectPayment ({commit}, {cb, errcb}) {
    doctor.getConnectPayment((resp) => {
      cb(resp)
    }, (err) => {
      errcb(err)
    })
  }
}

export default doctorActions;