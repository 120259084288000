import apiRoutes from './api_routes'
import { securedAxiosInstance, plainAxiosInstance } from './index'


const auth = {
	sendCode(phone_number, cb, errcb){
		plainAxiosInstance.post(apiRoutes.send_code, { phone_number: phone_number })
        .then(response => {
        	console.log(response);
        	console.log(response.data.result);
        	console.log(response.data.result==="ERROR");
			if (response.data.result === "ERROR") {
				console.log("shit");
				this.error = (response.response && response.response.data && response.response.data.error) || ''
				delete localStorage.csrf
				delete localStorage.signedIn
				errcb(this.error);
				return
			}
		
			this.error = ''
			// this.$router.replace('/records')
			cb()
        })
        .catch(error => {
        	this.error = (error.response && error.response.data && error.response.data.error) || ''
			delete localStorage.csrf
			delete localStorage.signedIn
			errcb(this.error);
        })
	},
	validateCode(phone_number, code, cb, errcb){
		plainAxiosInstance.post(apiRoutes.validate_code, { phone_number: phone_number, code: code })
        .then(response => {
			if (response.data.result == "ERROR") {
				this.error = (response.response && response.response.data && response.response.data.error) || ''
				delete localStorage.csrf
				delete localStorage.signedIn
				errcb(this.error);
				return
			}
		
			this.error = ''
			localStorage.csrf = response.data.jwt
			localStorage.signedIn = true
			// this.$router.replace('/records')
			cb(response);

        })
        .catch(error => {
        	this.error = (error.response && error.response.data && error.response.data.error) || ''
			delete localStorage.csrf
			delete localStorage.signedIn
			errcb(this.error);
        })


	},
	logout(cb){
		securedAxiosInstance.delete(apiRoutes.logout)
        .then(response => {
          delete localStorage.csrf
          delete localStorage.signedIn
          cb(response)
        })

	},
	refreshToken(){
		plainAxiosInstance.post(apiRoutes.refresh_token, {}, { headers: { 'X-CSRF-TOKEN': localStorage.csrf } })
		.then(response => {
			localStorage.csrf = response.data.csrf
			localStorage.signedIn = true
			// After another successfull refresh - repeat original request
			let retryConfig = error.response.config
			retryConfig.headers['X-CSRF-TOKEN'] = localStorage.csrf
			return plainAxiosInstance.request(retryConfig)
		}).catch(error => {
			delete localStorage.csrf
			delete localStorage.signedIn
			// redirect to signin if refresh fails
			location.replace('/')
			return Promise.reject(error)
		})
	}
}

export default auth