import doctorGetters from '../getters/doctor';
import doctorMutations from '../mutations/doctor';
import doctorActions from '../actions/doctor';


const state = {
  doctors: [],
  doctorsSize: 0,
  paginateOptions: {},
}

export default {
  namespaced: true,
  state,
  getters: doctorGetters,
  mutations: doctorMutations,
  actions: doctorActions,
};
