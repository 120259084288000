const webAddress = 'http://localhost:3000';
const baseApiRoute = '/panel/'
export default {
  base: '',
  send_code: baseApiRoute + 'send_code',
  validate_code: baseApiRoute + 'validate_code',
  refresh_token: baseApiRoute + 'refresh_token',
  logout: baseApiRoute + 'authentication',
  clinic: baseApiRoute + 'clinics',
  doctor: baseApiRoute + 'doctors',
  doctor_service: baseApiRoute + 'doctor_services',
  doctor_specialty: baseApiRoute + 'doctor_specialties',
  service_cost: baseApiRoute + 'service_costs',
  service: baseApiRoute + 'services',
  specialty: baseApiRoute + 'specialties',
  visit_service: baseApiRoute + 'visit_services',
  visit: baseApiRoute + 'visits',
  pet: baseApiRoute + 'pets',
  pet_owner: baseApiRoute + 'pet_owners',
  payment: baseApiRoute + 'payments',
  user: baseApiRoute + 'users',
  general_info: baseApiRoute + 'general_infos',
  global_general_info: '/general_infos',
  interaction: baseApiRoute + 'interactions',
  connect_payment: baseApiRoute + 'doctors/connect_payment'
}
