import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import router from '../routers/auth'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css';
import '../assets/css/auth.scss'
import "../assets/css/sass/overrides.sass";
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import Layout from '../pages/auth/auth.vue'
import store from '../store/index'
import apiRoutes from '../api/api_routes'
import '../assets/css/common.scss'
import '../assets/css/main.css'
// import './shared/axios_settings'
// import locale from 'element-ui/lib/locale/lang/en'
import * as api from '../api'

import globalMethods from './global_methods'
Vue.mixin(globalMethods)

Vue.use(Vuex)
Vue.use(VueRouter)
// Vue.use(ElementUI, { locale })
Vue.use(VueAxios, axios)
Vue.use(Vuetify)

const theme = {

  primary: "#6A887D",
  primaryColorLight: "#98b8ac",
  primaryColorDark: "#3f5b51",
  textOnPrimaryColor: "#fff",
  textOnPrimaryColorDark: "#fff",
  secondary: "#8b70f1",
  secondaryColorLight: "#bf9fff",
  secondaryColorDark: "#5744be",
  textOnSecondaryColor: "#000000",

  // primary: '#4CAF50',
  // secondary: '#9C27b0',
  // accent: '#9C27b0',
  // info: '#00CAE3',
}

Vue.prototype.$apiRoutes = apiRoutes
document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    router,
    store,
    vuetify: new Vuetify({
	    icons: {
	        iconfont: 'md',
	    },      
      theme: {
        themes: {
          dark: theme,
          light: theme,
        },
      },
	}),
    render: createEl => createEl(Layout),
  }).$mount('#app')
})