const doctorMutation = {
  setDoctors (state, getDoctor) {
    state.doctors = getDoctor[0]
    state.doctorsSize = getDoctor[1]
    state.paginateOptions = getDoctor[2]
  },
  addDoctor (state, doctor) {
    state.doctors = [...state.doctors, doctor]
  },
  updateDoctor(state, newDoctor) {
    state.doctors = state.doctors.map((doctor) => {
      if(doctor.id === newDoctor.id)
        return {...doctor, ...newDoctor}
      return doctor
    })
  },
  deleteDoctor(state, deleteDoctor){
    state.doctors = state.doctors.filter((doctor)=>{
      return doctor.id !== deleteDoctor;
    })
  },
  setDoctor (state, getDoctor) {
    state.doctor = getDoctor
  },
  // doctors.forEach((doctor)=>{

  // })
  // doctors.findIndex()
}

export default doctorMutation;