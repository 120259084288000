import axios from 'axios'
import apiRoutes from './api_routes'
import auth from './authentication'
import clinic from './clinic'
import doctor from './doctor'
import doctor_service from './doctor_service'
import doctor_specialty from './doctor_specialty'
import service_cost from './service_cost'
import service from './service'
import specialty from './specialty'
import user from './user'
import visit_service from './visit_service'
import visit from './visit'
import pet from './pet'
import pet_owner from './pet_owner'
import payment from './payment'
import general_info from './general_info'
import interaction from './interaction'

const securedAxiosInstance = axios.create({
  baseURL: apiRoutes.base,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
})

const plainAxiosInstance = axios.create({
  baseURL: apiRoutes.base,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
})

securedAxiosInstance.interceptors.request.use(config => {
  const method = config.method.toUpperCase()
  if (method !== 'OPTIONS' && method !== 'GET') {
    config.headers = {
      ...config.headers,
      'X-CSRF-TOKEN': localStorage.csrf
    }
  }
  return config
})

securedAxiosInstance.interceptors.response.use(null, error => {
  if (error.response && error.response.config && error.response.status === 401) {
    // If 401 by expired access cookie, we do a refresh request
    return auth.refreshToken();
  } else {
    return Promise.reject(error)
  }
})

export { securedAxiosInstance, plainAxiosInstance, clinic, doctor_service, doctor_specialty, doctor,
  service_cost, service, specialty, user, visit_service, visit, pet, pet_owner, payment, general_info, interaction }
  