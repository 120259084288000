import apiRoutes from './api_routes'
// import { securedAxiosrInstance, plainAxiosInstance } from './index'
import axios from 'axios'
import { deserialize } from 'jsonapi-deserializer'

const general_info = {
	newGeneralInfoForm (newGeneralInfo, cb, errcb) {
    axios.post(apiRoutes.general_info, newGeneralInfo)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data)})
      })
      .catch((err) => {
        console.log(err)
        errcb({result: "ERROR"})
      })
  },

  updateGeneralInfoForm (updateGeneralInfo, cb, errcb) {
    axios.put(apiRoutes.general_info + '/' + updateGeneralInfo[0], updateGeneralInfo[1])
      .then(response => {
        cb({result: "OK", data: deserialize(response.data)})
      })
      .catch(e => {
        errcb({result: "ERROR"})
      })
  },

  findGeneralInfo (findGeneralInfo, cb, errcb) {
    axios.get(apiRoutes.general_info + '/' + findGeneralInfo)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data)})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },

  getAllGeneralInfos (params, cb, errcb) {
    axios.get(apiRoutes.general_info + params)
      .then(response => {
        console.log("general_info>>>", deserialize(response.data))
        cb({result: "OK", data: deserialize(response.data), total_count: response.data.meta.total_count, meta: response.data.meta})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },
  getAllGlobalGeneralInfos (params, cb, errcb) {
    axios.get(apiRoutes.global_general_info + params)
      .then(response => {
        console.log("general_info>>>", deserialize(response.data))
        cb({result: "OK", data: deserialize(response.data), total_count: response.data.meta.total_count, meta: response.data.meta})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  }
}

export default general_info;
