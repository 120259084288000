import visitGetters from '../getters/visit';
import visitMutations from '../mutations/visit';
import visitActions from '../actions/visit';


const state = {
  visits: [],
  visitsSize: 0,
  paginateOptions: {},
}

export default {
  namespaced: true,
  state,
  getters: visitGetters,
  mutations: visitMutations,
  actions: visitActions,
};
