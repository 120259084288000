import clinicGetters from '../getters/clinic';
import clinicMutations from '../mutations/clinic';
import clinicActions from '../actions/clinic';


const state = {
  clinics: [],
  clinicsSize: 0,
  paginateOptions: {},
}

export default {
  namespaced: true,
  state,
  getters: clinicGetters,
  mutations: clinicMutations,
  actions: clinicActions,
};
