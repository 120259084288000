const userMutation = {
  setUsers (state, getUser) {
    state.users = getUser[0]
    state.usersSize = getUser[1]
    state.paginateOptions = getUser[2]
  },
  addUser (state, user) {
    state.users = [...state.users, user]
  },
  updateUser(state, newUser) {
    state.users = state.users.map((user) => {
      if(user.id === newUser.id)
        return {...user, ...newUser}
      return user
    })
  },
  deleteUser(state, deleteUser){
    state.users = state.users.filter((user)=>{
      return user.id !== deleteUser;
    })
  },
  setUser (state, getUser) {
    state.user = getUser
  },
  // users.forEach((user)=>{

  // })
  // users.findIndex()
}

export default userMutation;