const drawerMutation = {
  SET_BAR_IMAGE(state, payload) {
    state.barImage = payload;
  },
  SET_DRAWER(state, payload) {
    state.drawer = payload;
  },
  showMessage (state, payload) {
	state.content = payload.content
	state.color = payload.color
  },
}

export default drawerMutation;