import apiRoutes from './api_routes'
// import { securedAxiosrInstance, plainAxiosInstance } from './index'
import axios from 'axios'
import { deserialize } from 'jsonapi-deserializer'

const service_cost = {
	newServiceCostForm (newServiceCost, cb, errcb) {
    axios.post(apiRoutes.service_cost, newServiceCost)
      .then(() => {
        cb({result: "OK"})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },

  updateServiceCostForm (updateServiceCost, cb, errcb) {
    axios.put(apiRoutes.service_cost  + '/' + updateServiceCost[0], updateServiceCost[1])
      .then(() => {
        cb({result: "OK"})
      })
      .catch(e => {
        errcb({result: "ERROR"})
      })
  },

  findServiceCost (findServiceCost, cb, errcb) {
    axios.get(apiRoutes.service_cost + '/' + findServiceCost)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data)})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },

  getAllServiceCosts (params, cb, errcb) {
    axios.get(apiRoutes.service_cost + params)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data), total_count: response.data.meta.total_count, meta: response.data.meta})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  }
}

export default service_cost;