import { deserialize } from 'jsonapi-deserializer'
import {service_cost} from '../../api'
import apiRoutes from '../../api/api_routes'
import axios from 'axios'

const service_costActions = {
  newServiceCostForm ({commit}, {newServiceCost, cb}) {
    service_cost.newServiceCostForm(newServiceCost, (resp) => {
      commit('addServiceCost', {...newServiceCost, id: 1});
      cb(resp);
    })
  },
  updateServiceCostForm ({commit}, {updateServiceCost, cb}) {
    service_cost.updateServiceCostForm(updateServiceCost, (resp) => {
      commit('updateServiceCost', {...updateServiceCost, id: 1});
      cb(resp);
    })
  },
  getAllServiceCosts ({commit}, {params, cb}) {
    service_cost.getAllServiceCosts(params, (resp) => {
      console.log("getAllServiceCosts>>>>>",resp.data)
      commit('setServiceCosts', [resp.data, resp.total_count, resp.meta])
      cb(resp)
    })
  },
  deleteServiceCost ({commit, state}, service_cost_id) {
    axios.delete( apiRoutes.service_cost + '/' + service_cost_id)
    .then(() => {
      commit('deleteServiceCost', service_cost_id)
    })
    .catch(e => {
      console.log("!!!!!")
    })
  },
  findServiceCost ({commit}, {findServiceCost, cb}) {
    service_cost.findServiceCost(findServiceCost, (resp) => {
      commit('setServiceCost', {...findServiceCost});
      cb(resp);
    })
  }
}

export default service_costActions;