import apiRoutes from './api_routes'
// import { securedAxiosrInstance, plainAxiosInstance } from './index'
import axios from 'axios'
import { deserialize } from 'jsonapi-deserializer'

const interaction = {
	newInteractionForm (newInteraction, cb, errcb) {
    axios.post(apiRoutes.interaction, newInteraction)
      .then(() => {
        cb({result: "OK"})
      })
      .catch((err) => {
        console.log(err)
        errcb({result: "ERROR"})
      })
  },

  updateInteractionForm (updateInteraction, cb, errcb) {
    axios.put(apiRoutes.interaction  + '/' + updateInteraction[0], updateInteraction[1])
      .then(() => {
        cb({result: "OK"})
      })
      .catch(e => {
        errcb({result: "ERROR"})
      })
  },

  findInteraction (findInteraction, cb, errcb) {
    axios.get(apiRoutes.interaction + '/' + findInteraction)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data)})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },

  getAllInteractions (params, cb, errcb) {
    axios.get(apiRoutes.interaction + params)
      .then(response => {
        console.log(response)
        cb({result: "OK", data: deserialize(response.data), total_count: response.data.meta.total_count, meta: response.data.meta})
      })
      .catch((e) => {
        console.log(e)
        errcb({result: "ERROR"})
      })
  },
  getConnectPayment(cb, errcb) {
    axios.get(apiRoutes.connect_payment)
      .then(response => {
        cb({result: "OK", url: response.data.url})
      })
      .catch((e) => {
        console.log(e)
        errcb({result: "ERROR"})
      })
  }
}

export default interaction;