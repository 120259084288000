const doctor_serviceMutation = {
  setDoctorServices (state, getDoctorService) {
    state.doctor_services = getDoctorService[0]
    state.doctor_servicesSize = getDoctorService[1]
    state.paginateOptions = getDoctorService[2]
  },
  addDoctorService (state, doctor_service) {
    state.doctor_services = [...state.doctor_services, doctor_service]
  },
  updateDoctorService(state, newDoctorService) {
    state.doctor_services = state.doctor_services.map((doctor_service) => {
      if(doctor_service.id === newDoctorService.id)
        return {...doctor_service, ...newDoctorService}
      return doctor_service
    })
  },
  deleteDoctorService(state, deleteDoctorService){
    state.doctor_services = state.doctor_services.filter((doctor_service)=>{
      return doctor_service.id !== deleteDoctorService;
    })
  },
  setDoctorService (state, getDoctorService) {
    state.doctor_service = getDoctorService
  },
  // doctor_services.forEach((doctor_service)=>{

  // })
  // doctor_services.findIndex()
}

export default doctor_serviceMutation;