import routes from '../routes/auth'
import VueRouter from 'vue-router'

const router = new VueRouter({
  mode: 'history',
  base: '/panel/auth/',
  routes,
})

export default router
