import apiRoutes from './api_routes'
// import { securedAxiosrInstance, plainAxiosInstance } from './index'
import axios from 'axios'
import { deserialize } from 'jsonapi-deserializer'

const doctor = {
	newDoctorForm (newDoctor, cb, errcb) {
    axios.post(apiRoutes.doctor, newDoctor)
      .then(() => {
        cb({result: "OK"})
      })
      .catch((err) => {
        console.log(err)
        errcb({result: "ERROR"})
      })
  },

  updateDoctorForm (updateDoctor, cb, errcb) {
    axios.put(apiRoutes.doctor  + '/' + updateDoctor[0], updateDoctor[1])
      .then(() => {
        cb({result: "OK"})
      })
      .catch(e => {
        errcb({result: "ERROR"})
      })
  },

  findDoctor (findDoctor, cb, errcb) {
    axios.get(apiRoutes.doctor + '/' + findDoctor)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data)})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },

  getAllDoctors (params, cb, errcb) {
    axios.get(apiRoutes.doctor + params)
      .then(response => {
        console.log(response)
        cb({result: "OK", data: deserialize(response.data), total_count: response.data.meta.total_count, meta: response.data.meta})
      })
      .catch((e) => {
        console.log(e)
        errcb({result: "ERROR"})
      })
  },
  getConnectPayment(cb, errcb) {
    axios.get(apiRoutes.connect_payment)
      .then(response => {
        cb({result: "OK", url: response.data.url})
      })
      .catch((e) => {
        console.log(e)
        errcb({result: "ERROR"})
      })
  }
}

export default doctor;