import apiRoutes from './api_routes'
// import { securedAxiosrInstance, plainAxiosInstance } from './index'
import axios from 'axios'
import { deserialize } from 'jsonapi-deserializer'

const doctor_service = {
	newDoctorServiceForm (newDoctorService, cb, errcb) {
    axios.post(apiRoutes.doctor_service, newDoctorService)
      .then(() => {
        cb({result: "OK"})
      })
      .catch(() => {
        console.log("here")
        errcb({result: "ERROR"})
      })
  },

  updateDoctorServiceForm (updateDoctorService, cb, errcb) {
    axios.put(apiRoutes.doctor_service  + '/' + updateDoctorService[0], updateDoctorService[1])
      .then(() => {
        cb({result: "OK"})
      })
      .catch(e => {
        errcb({result: "ERROR"})
      })
  },

  findDoctorService (findDoctorService, cb, errcb) {
    axios.get(apiRoutes.doctor_service + '/' + findDoctorService)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data)})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },

  getAllDoctorServices (params, cb, errcb) {
    axios.get(apiRoutes.doctor_service + params)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data), total_count: response.data.meta.total_count, meta: response.data.meta})
      })
      .catch((e) => {
        console.log(e)
        errcb({result: "ERROR"})
      })
  }
}

export default doctor_service;