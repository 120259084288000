const general_infoMutation = {
  setGeneralInfos (state, getGeneralInfo) {
    state.general_infos = getGeneralInfo[0]
    state.general_infosSize = getGeneralInfo[1]
    state.paginateOptions = getGeneralInfo[2]
  },
  addGeneralInfo (state, general_info) {
    state.general_infos = [state.general_infos, general_info]
  },
  updateGeneralInfo(state, newGeneralInfo) {
    state.general_infos = state.general_infos.map((general_info) => {
      if(general_info.id === newGeneralInfo.id)
        return {...general_info, ...newGeneralInfo}
      return general_info
    })
  },
  deleteGeneralInfo(state, deleteGeneralInfo){
    state.general_infos = state.general_infos.filter((general_info)=>{
      return general_info.id === deleteGeneralInfo;
    })
  },
  setGeneralInfo (state, getGeneralInfo) {
    state.general_info = getGeneralInfo
  },
  // general_infos.forEach((general_info)=>{

  // })
  // general_infos.findIndex()
}

export default general_infoMutation;