import { deserialize } from 'jsonapi-deserializer'
import {service} from '../../api'
import apiRoutes from '../../api/api_routes'
import axios from 'axios'

const serviceActions = {
  newServiceForm ({commit}, {newService, cb}) {
    service.newServiceForm(newService, (resp) => {
      commit('addService', {...newService, id: 1});
      cb(resp);
    })
  },
  updateServiceForm ({commit}, {updateService, cb}) {
    service.updateServiceForm(updateService, (resp) => {
      commit('updateService', {...updateService, id: 1});
      cb(resp);
    })
  },
  getAllServices ({commit}, {params, cb}) {
    service.getAllServices(params, (resp) => {
      console.log("getAllServices>>>>>",resp.data)
      commit('setServices', [resp.data, resp.total_count, resp.meta])
      cb(resp)
    })
  },
  deleteService ({commit, state}, {service_id, cb}) {
    axios.delete( apiRoutes.service + '/' + service_id)
    .then(() => {
      commit('deleteService', service_id)
      cb({result: "OK"})
    })
    .catch(e => {
      console.log("!!!!!")
      cb({result: "ERROR"})
    })
  },
  findService ({commit}, {findService, cb}) {
    service.findService(findService, (resp) => {
      commit('setService', {...findService});
      cb(resp);
    })
  }
}

export default serviceActions;