import apiRoutes from './api_routes'
// import { securedAxiosrInstance, plainAxiosInstance } from './index'
import axios from 'axios'
import { deserialize } from 'jsonapi-deserializer'

const payment = {
	newPaymentForm (newPayment, cb, errcb) {
    axios.post(apiRoutes.payment, newPayment)
      .then(() => {
        cb({result: "OK"})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },

  updatePaymentForm (updatePayment, cb, errcb) {
    axios.put(apiRoutes.payment  + '/' + updatePayment[0], updatePayment[1])
      .then(() => {
        cb({result: "OK"})
      })
      .catch(e => {
        errcb({result: "ERROR"})
      })
  },

  findPayment (findPayment, cb, errcb) {
    axios.get(apiRoutes.payment + '/' + findPayment)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data)})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },

  getAllPayments (params, cb, errcb) {
    axios.get(apiRoutes.payment + params)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data), total_count: response.data.meta.total_count, meta: response.data.meta})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  }
}

export default payment;