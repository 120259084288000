const paymentMutation = {
  setPayments (state, getPayment) {
    state.payments = getPayment[0]
    state.paymentsSize = getPayment[1]
    state.paginateOptions = getPayment[2]
  },
  addPayment (state, payment) {
    state.payments = [...state.payments, payment]
  },
  updatePayment(state, newPayment) {
    state.payments = state.payments.map((payment) => {
      if(payment.id === newPayment.id)
        return {...payment, ...newPayment}
      return payment
    })
  },
  deletePayment(state, deletePayment){
    state.payments = state.payments.filter((payment)=>{
      return payment.id === deletePayment;
    })
  },
  setPayment (state, getPayment) {
    state.payment = getPayment
  },
  // payments.forEach((payment)=>{

  // })
  // payments.findIndex()
}

export default paymentMutation;