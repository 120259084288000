import { deserialize } from 'jsonapi-deserializer'
import {pet_owner} from '../../api'
import apiRoutes from '../../api/api_routes'
import axios from 'axios'

const pet_ownerActions = {
  newPetOwnerForm ({commit}, {newPetOwner, cb}) {
    pet_owner.newPetOwnerForm(newPetOwner, (resp) => {
      commit('addPetOwner', {...newPetOwner, id: 1});
      cb(resp);
    })
  },
  updatePetOwnerForm ({commit}, {updatePetOwner, cb}) {
    pet_owner.updatePetOwnerForm(updatePetOwner, (resp) => {
      commit('updatePetOwner', {...updatePetOwner, id: 1});
      cb(resp);
    })
  },
  getAllPetOwners ({commit}, {params, cb}) {
    pet_owner.getAllPetOwners(params, (resp) => {
      console.log("getAllPetOwners>>>>>",resp.data)
      commit('setPetOwners', [resp.data, resp.total_count, resp.meta])
      cb(resp)
    })
  },
  deletePetOwner ({commit, state}, {pet_owner_id, cb}) {
    console.log(pet_owner_id)
    axios.delete( apiRoutes.pet_owner + '/' + pet_owner_id)
    .then(() => {
      commit('deletePetOwner', pet_owner_id)
      cb({result: "OK"})
    })
    .catch(e => {
      console.log("!!!!!")
    })
  },
  findPetOwner ({commit}, {findPetOwner, cb}) {
    pet_owner.findPetOwner(findPetOwner, (resp) => {
      commit('setPetOwner', {...findPetOwner});
      cb(resp);
    })
  }
}

export default pet_ownerActions;