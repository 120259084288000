import { deserialize } from 'jsonapi-deserializer'
import {user} from '../../api'
import apiRoutes from '../../api/api_routes'
import axios from 'axios'

const userActions = {
  newUserForm ({commit}, {newUser, cb}) {
    user.newUserForm(newUser, (resp) => {
      commit('addUser', {...newUser, id: 1});
      cb(resp);
    })
  },
  updateUserForm ({commit}, {updateUser, cb}) {
    user.updateUserForm(updateUser, (resp) => {
      commit('updateUser', {...updateUser, id: 1});
      cb(resp);
    })
  },
  getAllUsers ({commit}, {params, cb}) {
    user.getAllUsers(params, (resp) => {
      commit('setUsers', [resp.data, resp.total_count, resp.meta])
      cb(resp)
    })
  },
  deleteUser ({commit, state}, user_id) {
    axios.delete( apiRoutes.user + '/' + user_id)
    .then(() => {
      commit('deleteUser', user_id)
    })
    .catch(e => {
      console.log("!!!!!")
    })
  },
  findUser ({commit}, {findUser, cb}) {
    user.findUser(findUser, (resp) => {
      commit('setUser', {...findUser});
      cb(resp);
    })
  }
}

export default userActions;