const visit_serviceMutation = {
  setVisitServices (state, getVisitService) {
    state.visit_services = getVisitService[0]
    state.visit_servicesSize = getVisitService[1]
    state.paginateOptions = getVisitService[2]
  },
  addVisitService (state, visit_service) {
    state.visit_services = [...state.visit_services, visit_service]
  },
  updateVisitService(state, newVisitService) {
    state.visit_services = state.visit_services.map((visit_service) => {
      if(visit_service.id === newVisitService.id)
        return {...visit_service, ...newVisitService}
      return visit_service
    })
  },
  deleteVisitService(state, deleteVisitService){
    state.visit_services = state.visit_services.filter((visit_service)=>{
      return visit_service.id !== deleteVisitService;
    })
  },
  setVisitService (state, getVisitService) {
    state.visit_service = getVisitService
  },
  // visit_services.forEach((visit_service)=>{

  // })
  // visit_services.findIndex()
}

export default visit_serviceMutation;