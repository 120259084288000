import paymentGetters from '../getters/payment';
import paymentMutations from '../mutations/payment';
import paymentActions from '../actions/payment';


const state = {
  payments: [],
  paymentsSize: 0,
  paginateOptions: {},
}

export default {
  namespaced: true,
  state,
  getters: paymentGetters,
  mutations: paymentMutations,
  actions: paymentActions,
};
