
import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import clinic from './modules/clinic'
import doctor from './modules/doctor'
import doctor_service from './modules/doctor_service'
import service_cost from './modules/service_cost'
import service from './modules/service'
import visit_service from './modules/visit_service'
import visit from './modules/visit'
import pet from './modules/pet'
import payment from './modules/payment'
import pet_owner from './modules/pet_owner'
import drawer from './modules/drawer'
import general_info from './modules/general_info'
import interaction from './modules/interaction'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user, clinic, doctor_service, doctor, service_cost,
    service, visit_service, visit, pet, pet_owner, payment, drawer, general_info, interaction
  },
})