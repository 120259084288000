import Login from '../pages/auth/login.vue'

const routes = [
  { path: '/',
    name: 'login_page',
    components: {
      default: Login,
    },
  }
]

export default routes
