import doctor_serviceGetters from '../getters/doctor_service';
import doctor_serviceMutations from '../mutations/doctor_service';
import doctor_serviceActions from '../actions/doctor_service';


const state = {
  doctor_services: [],
  doctor_servicesSize: 0,
  paginateOptions: {},
}

export default {
  namespaced: true,
  state,
  getters: doctor_serviceGetters,
  mutations: doctor_serviceMutations,
  actions: doctor_serviceActions,
};
