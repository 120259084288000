import interactionGetters from '../getters/interaction';
import interactionMutations from '../mutations/interaction';
import interactionActions from '../actions/interaction';


const state = {
  interactions: [],
  interactionsSize: 0,
  paginateOptions: {},
}

export default {
  namespaced: true,
  state,
  getters: interactionGetters,
  mutations: interactionMutations,
  actions: interactionActions,
};
