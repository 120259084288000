import apiRoutes from './api_routes'
// import { securedAxiosrInstance, plainAxiosInstance } from './index'
import axios from 'axios'
import { deserialize } from 'jsonapi-deserializer'

const visit = {
	newVisitForm (newVisit, cb, errcb) {
    axios.post(apiRoutes.visit, newVisit)
      .then(() => {
        cb({result: "OK"})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },

  updateVisitForm (updateVisit, cb, errcb) {
    axios.put(apiRoutes.visit  + '/' + updateVisit[0], updateVisit[1])
      .then(() => {
        cb({result: "OK"})
      })
      .catch(e => {
        errcb({result: "ERROR"})
      })
  },

  findVisit (findVisit, cb, errcb) {
    axios.get(apiRoutes.visit + '/' + findVisit)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data)})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },

  getAllVisits (params, cb, errcb) {
    axios.get(apiRoutes.visit + params)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data), total_count: response.data.meta.total_count, meta: response.data.meta})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  }
}

export default visit;