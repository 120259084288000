const service_costMutation = {
  setServiceCosts (state, getServiceCost) {
    state.service_costs = getServiceCost[0]
    state.service_costsSize = getServiceCost[1]
    state.paginateOptions = getServiceCost[2]
  },
  addServiceCost (state, service_cost) {
    state.service_costs = [...state.service_costs, service_cost]
  },
  updateServiceCost(state, newServiceCost) {
    state.service_costs = state.service_costs.map((service_cost) => {
      if(service_cost.id === newServiceCost.id)
        return {...service_cost, ...newServiceCost}
      return service_cost
    })
  },
  deleteServiceCost(state, deleteServiceCost){
    state.service_costs = state.service_costs.filter((service_cost)=>{
      return service_cost.id !== deleteServiceCost;
    })
  },
  setServiceCost (state, getServiceCost) {
    state.service_cost = getServiceCost
  },
  // service_costs.forEach((service_cost)=>{

  // })
  // service_costs.findIndex()
}

export default service_costMutation;