import apiRoutes from './api_routes'
// import { securedAxiosrInstance, plainAxiosInstance } from './index'
import axios from 'axios'
import { deserialize } from 'jsonapi-deserializer'

const service = {
	newServiceForm (newService, cb, errcb) {
    axios.post(apiRoutes.service, newService)
      .then(() => {
        cb({result: "OK"})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },

  updateServiceForm (updateService, cb, errcb) {
    axios.put(apiRoutes.service  + '/' + updateService[0], updateService[1])
      .then(() => {
        cb({result: "OK"})
      })
      .catch(e => {
        errcb({result: "ERROR"})
      })
  },

  findService (findService, cb, errcb) {
    axios.get(apiRoutes.service + '/' + findService)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data)})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },

  getAllServices (params, cb, errcb) {
    axios.get(apiRoutes.service + params)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data), total_count: response.data.meta.total_count, meta: response.data.meta})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  }
}

export default service;