import drawerMutation from '../mutations/drawer';

const state = {
  // barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barColor: "rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)",
    /*barImage:
      "https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg",*/
    drawer: true,
    content: '',
    color: '',
}

export default {
  namespaced: true,
  state,
  mutations: drawerMutation,
};
 




