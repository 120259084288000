import apiRoutes from './api_routes'
// import { securedAxiosrInstance, plainAxiosInstance } from './index'
import axios from 'axios'
import { deserialize } from 'jsonapi-deserializer'

const pet_owner = {
	newPetOwnerForm (newPetOwner, cb, errcb) {
    axios.post(apiRoutes.pet_owner, newPetOwner)
      .then(() => {
        cb({result: "OK"})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },

  updatePetOwnerForm (updatePetOwner, cb, errcb) {
    axios.put(apiRoutes.pet_owner  + '/' + updatePetOwner[0], updatePetOwner[1])
      .then(() => {
        cb({result: "OK"})
      })
      .catch(e => {
        errcb({result: "ERROR"})
      })
  },

  findPetOwner (findPetOwner, cb, errcb) {
    axios.get(apiRoutes.pet_owner + '/' + findPetOwner)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data)})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },

  getAllPetOwners (params, cb, errcb) {
    axios.get(apiRoutes.pet_owner + params)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data), total_count: response.data.meta.total_count, meta: response.data.meta})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  }
}

export default pet_owner;