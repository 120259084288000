import apiRoutes from './api_routes'
// import { securedAxiosrInstance, plainAxiosInstance } from './index'
import axios from 'axios'
import { deserialize } from 'jsonapi-deserializer'

const clinic = {
	newClinicForm (newClinic, cb, errcb) {
    axios.post(apiRoutes.clinic, newClinic)
      .then(() => {
        cb({result: "OK"})
      })
      .catch((e) => {
        console.log(e)
        errcb({result: "ERROR"})
      })
  },

  updateClinicForm (updateClinic, cb, errcb) {
    axios.put(apiRoutes.clinic  + '/' + updateClinic[0], updateClinic[1])
      .then(() => {
        cb({result: "OK"})
      })
      .catch(e => {
        errcb({result: "ERROR"})
      })
  },

  findClinic (findClinic, cb, errcb) {
    axios.get(apiRoutes.clinic + '/' + findClinic)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data)})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },

  getAllClinics (params, cb, errcb) {
    axios.get(apiRoutes.clinic + params)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data), total_count: response.data.meta.total_count, meta: response.data.meta})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  }
}

export default clinic;