import petGetters from '../getters/pet';
import petMutations from '../mutations/pet';
import petActions from '../actions/pet';


const state = {
  pets: [],
  petsSize: 0,
  paginateOptions: {},
  pet: {}
}

export default {
  namespaced: true,
  state,
  getters: petGetters,
  mutations: petMutations,
  actions: petActions,
};
