import pet_ownerGetters from '../getters/pet_owner';
import pet_ownerMutations from '../mutations/pet_owner';
import pet_ownerActions from '../actions/pet_owner';


const state = {
  pet_owners: [],
  pet_ownersSize: 0,
  paginateOptions: {},
}

export default {
  namespaced: true,
  state,
  getters: pet_ownerGetters,
  mutations: pet_ownerMutations,
  actions: pet_ownerActions,
};
