const interactionMutation = {
  setInteractions (state, getInteraction) {
    state.interactions = getInteraction[0]
    state.interactionsSize = getInteraction[1]
    state.paginateOptions = getInteraction[2]
  },
  addInteraction (state, interaction) {
    state.interactions = [...state.interactions, interaction]
  },
  updateInteraction(state, newInteraction) {
    state.interactions = state.interactions.map((interaction) => {
      if(interaction.id === newInteraction.id)
        return {...interaction, ...newInteraction}
      return interaction
    })
  },
  deleteInteraction(state, deleteInteraction){
    state.interactions = state.interactions.filter((interaction)=>{
      return interaction.id !== deleteInteraction;
    })
  },
  setInteraction (state, getInteraction) {
    state.interaction = getInteraction
  },
  // interactions.forEach((interaction)=>{

  // })
  // interactions.findIndex()
}

export default interactionMutation;