// import { deserialize } from 'jsonapi-deserializer'
import {clinic} from '../../api'
import apiRoutes from '../../api/api_routes'
import axios from 'axios'

const clinicActions = {
  newClinicForm ({commit}, {newClinic, cb}) {
    clinic.newClinicForm(newClinic, (resp) => {
      commit('addClinic', {...newClinic, id: 1});
      cb(resp);
    })
  },
  updateClinicForm ({commit}, {updateClinic, cb}) {
    clinic.updateClinicForm(updateClinic, (resp) => {
      commit('updateClinic', {...updateClinic, id: 1});
      cb(resp);
    })
  },
  getAllClinics ({commit}, {params, cb}) {
    clinic.getAllClinics(params, (resp) => {
      console.log("getAllClinics>>>>>",resp.data)
      commit('setClinics', [resp.data, resp.total_count, resp.meta])
      cb(resp)
    })
  },
  deleteClinic ({commit, state}, {clinic_id, cb}) {
    axios.delete( apiRoutes.clinic + '/' + clinic_id)
    .then(() => {
      commit('deleteClinic', clinic_id)
      cb({result: "OK"})
    })
    .catch(e => {
      console.log(e)
      cb({result: "ERROR"})
    })
  },
  findClinic ({commit}, {findClinic, cb}) {
    clinic.findClinic(findClinic, (resp) => {
      commit('setClinic', {...findClinic});
      cb(resp);
    })
  }
}

export default clinicActions;