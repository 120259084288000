import { deserialize } from 'jsonapi-deserializer'
import {interaction} from '../../api'
import apiRoutes from '../../api/api_routes'
import axios from 'axios'

const interactionActions = {
  newInteractionForm ({commit}, {newInteraction, cb}) {
    interaction.newInteractionForm(newInteraction, (resp) => {
      commit('addInteraction', {...newInteraction, id: 1});
      cb(resp);
    })
  },
  updateInteractionForm ({commit}, {updateInteraction, cb}) {
    interaction.updateInteractionForm(updateInteraction, (resp) => {
      commit('updateInteraction', {...updateInteraction, id: 1});
      cb(resp);
    })
  },
  getAllInteractions ({commit}, {params, cb}) {
    interaction.getAllInteractions(params, (resp) => {
      console.log("getAllInteractions>>>>>",resp.data)
      commit('setInteractions', [resp.data, resp.total_count, resp.meta])
      cb(resp)
    })
  },
  deleteInteraction ({commit, state}, {deleteInteraction}) {
    console.log("interaction", deleteInteraction)
    axios.delete( apiRoutes.interaction + '/' + deleteInteraction)
    .then(() => {
      commit('deleteInteraction', deleteInteraction)
    })
    .catch(e => {
      console.log("!!!!!")
    })
  },
  findInteraction ({commit}, {findInteraction, cb}) {
    interaction.findInteraction(findInteraction, (resp) => {
      commit('setInteraction', {...findInteraction});
      cb(resp);
    })
  }
}

export default interactionActions;