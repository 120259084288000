import { deserialize } from 'jsonapi-deserializer'
import {visit} from '../../api'
import apiRoutes from '../../api/api_routes'
import axios from 'axios'

const visitActions = {
  newVisitForm ({commit}, {newVisit, cb}) {
    visit.newVisitForm(newVisit, (resp) => {
      commit('addVisit', {...newVisit, id: 1});
      cb(resp);
    })
  },
  updateVisitForm ({commit}, {updateVisit, cb}) {
    visit.updateVisitForm(updateVisit, (resp) => {
      commit('updateVisit', {...updateVisit, id: 1});
      cb(resp);
    })
  },
  getAllVisits ({commit}, {params, cb}) {
    visit.getAllVisits(params, (resp) => {
      console.log("getAllVisits>>>>>",resp.data)
      commit('setVisits', [resp.data, resp.total_count, resp.meta])
      cb(resp)
    })
  },
  deleteVisit ({commit, state}, {deleteVisit, cb}) {
    axios.delete( apiRoutes.visit + '/' + deleteVisit)
    .then(() => {
      commit('deleteVisit', deleteVisit)
      cb({result: "OK"})
    })
    .catch(e => {
      console.log("!!!!!")
    })
  },
  findVisit ({commit}, {findVisit, cb}) {
    visit.findVisit(findVisit, (resp) => {
      commit('setVisit', {...findVisit});
      cb(resp);
    })
  }
}

export default visitActions;