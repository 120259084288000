import { deserialize } from 'jsonapi-deserializer'
import {general_info} from '../../api'
import apiRoutes from '../../api/api_routes'
import axios from 'axios'

const general_infoActions = {
  newGeneralInfoForm ({commit}, {newGeneralInfo, cb}) {
    general_info.newGeneralInfoForm(newGeneralInfo, (resp) => {
      commit('addGeneralInfo', {...newGeneralInfo, id: 1});
      cb(resp);
    })
  },
  updateGeneralInfoForm ({commit}, {updateGeneralInfo, cb}) {
    general_info.updateGeneralInfoForm(updateGeneralInfo, (resp) => {
      commit('updateGeneralInfo', {...updateGeneralInfo, id: 1});
      cb(resp);
    })
  },
  getAllGeneralInfos ({commit}, {params, cb}) {
    general_info.getAllGeneralInfos(params, (resp) => {
      commit('setGeneralInfos', [resp.data, resp.total_count, resp.meta])
      cb(resp)
    })
  },
  getAllGlobalGeneralInfos ({commit}, {params, cb}) {
    general_info.getAllGlobalGeneralInfos(params, (resp) => {
      commit('setGeneralInfos', [resp.data, resp.total_count, resp.meta])
      cb(resp)
    })
  },
  deleteGeneralInfo ({commit, state}, general_info_id) {
    axios.delete( apiRoutes.general_info + '/' + general_info_id)
    .then(() => {
      commit('deleteGeneralInfo', {...state, general_info_id})
    })
    .catch(e => {
      console.log("!!!!!")
    })
  },
  findGeneralInfo ({commit}, {findGeneralInfo, cb}) {
    general_info.findGeneralInfo(findGeneralInfo, (resp) => {
      commit('setGeneralInfo', {...findGeneralInfo});
      cb(resp);
    })
  }
}

export default general_infoActions;