import serviceGetters from '../getters/service';
import serviceMutations from '../mutations/service';
import serviceActions from '../actions/service';


const state = {
  services: [],
  servicesSize: 0,
  paginateOptions: {},
}

export default {
  namespaced: true,
  state,
  getters: serviceGetters,
  mutations: serviceMutations,
  actions: serviceActions,
};
