const serviceMutation = {
  setServices (state, getService) {
    state.services = getService[0]
    state.servicesSize = getService[1]
    state.paginateOptions = getService[2]
  },
  addService (state, service) {
    state.services = [...state.services, service]
  },
  updateService(state, newService) {
    state.services = state.services.map((service) => {
      if(service.id === newService.id)
        return {...service, ...newService}
      return service
    })
  },
  deleteService(state, deleteService){
    console.log("dfa", deleteService, state.services)
    state.services = state.services.filter((service)=>{
      return service.id !== deleteService;
    })
  },
  setService (state, getService) {
    state.service = getService
  },
  // services.forEach((service)=>{

  // })
  // services.findIndex()
}

export default serviceMutation;