import { deserialize } from 'jsonapi-deserializer'
import {pet} from '../../api'
import apiRoutes from '../../api/api_routes'
import axios from 'axios'

const petActions = {
  newPetForm ({commit}, {newPet, cb}) {
    pet.newPetForm(newPet, (resp) => {
      commit('addPet', {...newPet, id: 1});
      cb(resp);
    })
  },
  updatePetForm ({commit}, {updatePet, cb}) {
    pet.updatePetForm(updatePet, (resp) => {
      commit('updatePet', {...updatePet, id: 1});
      cb(resp);
    })
  },
  getAllPets ({commit}, {params, cb}) {
    pet.getAllPets(params, (resp) => {
      console.log("getAllPets>>>>>",resp.data)
      commit('setPets', [resp.data, resp.total_count, resp.meta])
      cb(resp)
    })
  },
  deletePet ({commit, state}, pet_id) {
    axios.delete( apiRoutes.pet + '/' + pet_id)
    .then(() => {
      commit('deletePet', pet_id)
    })
    .catch(e => {
      console.log("!!!!!")
    })
  },
  findPet ({commit}, {findPet, cb}) {
    pet.findPet(findPet, (resp) => {
      commit('setPet', resp.data);
      cb(resp);
    })
  }
}

export default petActions;