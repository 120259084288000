import apiRoutes from './api_routes'
// import { securedAxiosrInstance, plainAxiosInstance } from './index'
import axios from 'axios'
import { deserialize } from 'jsonapi-deserializer'

const user = {
	newUserForm (newUser, cb, errcb) {
    axios.post(apiRoutes.user, newUser)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data)})
      })
      .catch((err) => {
        console.log(err)
        errcb({result: "ERROR"})
      })
  },

  updateUserForm (updateUser, cb, errcb) {
    axios.put(apiRoutes.user + '/' + updateUser[0], updateUser[1])
      .then(response => {
        cb({result: "OK", data: deserialize(response.data)})
      })
      .catch(e => {
        errcb({result: "ERROR"})
      })
  },

  findUser (findUser, cb, errcb) {
    axios.get(apiRoutes.user + '/' + findUser)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data)})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },

  getAllUsers (params, cb, errcb) {
    axios.get(apiRoutes.user + params)
      .then(response => {
        console.log("user>>>", deserialize(response.data))
        cb({result: "OK", data: deserialize(response.data), total_count: response.data.meta.total_count, meta: response.data.meta})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  }
}

export default user;
