import { deserialize } from 'jsonapi-deserializer'
import {visit_service} from '../../api'
import apiRoutes from '../../api/api_routes'
import axios from 'axios'

const visit_serviceActions = {
  newVisitServiceForm ({commit}, {newVisitService, cb}) {
    visit_service.newVisitServiceForm(newVisitService, (resp) => {
      commit('addVisitService', {...newVisitService, id: 1});
      cb(resp);
    })
  },
  updateVisitServiceForm ({commit}, {updateVisitService, cb}) {
    visit_service.updateVisitServiceForm(updateVisitService, (resp) => {
      commit('updateVisitService', {...updateVisitService, id: 1});
      cb(resp);
    })
  },
  getAllVisitServices ({commit}, {params, cb}) {
    visit_service.getAllVisitServices(params, (resp) => {
      console.log("getAllVisitServices>>>>>",resp.data)
      commit('setVisitServices', [resp.data, resp.total_count, resp.meta])
      cb(resp)
    })
  },
  deleteVisitService ({commit, state}, visit_service_id) {
    axios.delete( apiRoutes.visit_service + '/' + visit_service_id)
    .then(() => {
      commit('deleteVisitService', visit_service_id)
    })
    .catch(e => {
      console.log("!!!!!")
    })
  },
  findVisitService ({commit}, {findVisitService, cb}) {
    visit_service.findVisitService(findVisitService, (resp) => {
      commit('setVisitService', {...findVisitService});
      cb(resp);
    })
  }
}

export default visit_serviceActions;