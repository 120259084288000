import { deserialize } from 'jsonapi-deserializer'

export default {
  data () {
    return {
    }
  },
  filters: {
    splitWords (string, separator = ' ', merger = ' ') {
      return (string !== null ? string.split(separator).join(merger) : '')
    },
    replaceWords (string, separator = ' ', merger = ' ') {
      return string.replace(separator, merger)
    },
  },
  computed: {
    current_panel_user () {
      return JSON.parse(document.getElementsByName('current_panel_user')[0].getAttribute('content'))
    },
    current_user_roles () {
      return this.$store.getters.getCurrentUserRoles
    },
  },
  methods: {
    errorMessage (statusCode, error, messageType = null) {
      let msgType = messageType || 'error'
      if (statusCode === -1) {
        this.showAlertMessage(error, msgType)
        return
      }

      try {
        let errorMessageResponse = null
        let errorMsg = ''
        errorMessageResponse = JSON.parse(error.request.response).error
        Object.entries(errorMessageResponse).forEach(item => {
          errorMsg += '<p>' + item[0] + ': ' + item[1].join() + '</p>'
        })

        this.showAlertMessage(errorMsg, msgType)
      } catch (e) {
        if (error.request.statusText === '') {
          this.showAlertMessage('Network Error', msgType)
          return
        }
        this.showAlertMessage(error.request.statusText, msgType)
      }
    },
    showAlertMessage (errorMessageResponse, msgType) {
      this.$message({
        showClose: true,
        message: errorMessageResponse,
        type: msgType,
        duration: 3000,
        dangerouslyUseHTMLString: true,
      })
    },
    truncate (string, length = 20) {
      if (this.isBlank(string)) return string
      if (string.length > length) {
        return string.substring(0, length) + '...'
      }
      return string
    },
    formatedTime (time) {
      let date = new Date(time)
      return date.toUTCString()
    },
    pluck (array, property) {
      let plucked = []
      for (let i = 0; i < array.length; ++i) {
        plucked.push(array[i][property])
      }
      return plucked
    },
    convertUTC (string) {
      return this.$moment.parseZone(string).local().format('ddd, MMM D, YYYY [at] h:mm A')
    },
    isBlank (value) {
      if (value === '' || value === null || value === undefined) {
        return true
      }
      return false
    },
    isEmpty (item) {
      if (this.isBlank(item)) return true
      if (Object.keys(item).length === 0) {
        return true
      }
      return false
    },
    formatTime (seconds) {
      if (seconds >= 86400) {
        seconds = seconds - 86400
        return this.$moment.utc(seconds * 1000).format('DDD[d] HH[h] mm[m] ss[s]')
      }
      return this.$moment.utc(seconds * 1000).format('HH[h] mm[m] ss[s]')
    },
    minusTimeFormat (seconds) {
      if (Math.abs(seconds) >= 86400) {
        seconds = Math.abs(seconds) - 86400
        return ' - ' + this.$moment.utc(Math.abs(seconds) * 1000).format('DDD[d] HH[h] mm[m] ss[s]')
      }
      return ' - ' + this.$moment.utc(Math.abs(seconds) * 1000).format('HH[h] mm[m] ss[s]')
    },
    datetimeFormat (value = undefined) {
      return this.$moment(value).local().format('DD.MM.YYYY HH:mm')
    },
    formatDate (value = undefined) {
      return this.$moment(value).local().format('DD.MM.YYYY')
    },
    formatHourMinute (value = undefined) {
      return this.$moment(value).local().format('HH:mm')
    },
    deserializer (value) {
      return deserialize(value)
    },
    sortedBy (array, key) {
      if (this.isEmpty(array)) return array
      return array.sort((a, b) => a[key] - b[key])
    },
  },
}
