<template>
                    
    <router-view />
</template>

<script>
import logo from 'assets/images/secure_account.svg'
export default {
  data () {
    return {
      appName: 'Auth',
      logo: logo,
    }
  },
}
</script>