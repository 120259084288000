import userGetters from '../getters/user';
import userMutations from '../mutations/user';
import userActions from '../actions/user';


const state = {
  users: [],
  usersSize: 0,
  paginateOptions: {},
}

export default {
  namespaced: true,
  state,
  getters: userGetters,
  mutations: userMutations,
  actions: userActions,
};
