import visit_serviceGetters from '../getters/visit_service';
import visit_serviceMutations from '../mutations/visit_service';
import visit_serviceActions from '../actions/visit_service';


const state = {
  visit_services: [],
  visit_servicesSize: 0,
  paginateOptions: {},
}

export default {
  namespaced: true,
  state,
  getters: visit_serviceGetters,
  mutations: visit_serviceMutations,
  actions: visit_serviceActions,
};
