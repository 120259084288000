import general_infoGetters from '../getters/general_info';
import general_infoMutations from '../mutations/general_info';
import general_infoActions from '../actions/general_info';


const state = {
  general_infos: [],
  general_infosSize: 0,
  paginateOptions: {},
}

export default {
  namespaced: true,
  state,
  getters: general_infoGetters,
  mutations: general_infoMutations,
  actions: general_infoActions,
};
