import service_costGetters from '../getters/service_cost';
import service_costMutations from '../mutations/service_cost';
import service_costActions from '../actions/service_cost';


const state = {
  service_costs: [],
  service_costsSize: 0,
  paginateOptions: {},
}

export default {
  namespaced: true,
  state,
  getters: service_costGetters,
  mutations: service_costMutations,
  actions: service_costActions,
};
