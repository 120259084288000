import apiRoutes from './api_routes'
// import { securedAxiosrInstance, plainAxiosInstance } from './index'
import axios from 'axios'
import { deserialize } from 'jsonapi-deserializer'

const visit_service = {
	newVisitServiceForm (newVisitService, cb, errcb) {
    axios.post(apiRoutes.visit_service, newVisitService)
      .then(() => {
        cb({result: "OK"})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },

  updateVisitServiceForm (updateVisitService, cb, errcb) {
    axios.put(apiRoutes.visit_service  + '/' + updateVisitService[0], updateVisitService[1])
      .then(() => {
        cb({result: "OK"})
      })
      .catch(e => {
        errcb({result: "ERROR"})
      })
  },

  findVisitService (findVisitService, cb, errcb) {
    axios.get(apiRoutes.visit_service + '/' + findVisitService)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data)})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  },

  getAllVisitServices (parmas, cb, errcb) {
    axios.get(apiRoutes.visit_service + parmas)
      .then(response => {
        cb({result: "OK", data: deserialize(response.data), total_count: response.data.meta.total_count, meta: response.data.meta})
      })
      .catch(() => {
        errcb({result: "ERROR"})
      })
  }
}

export default visit_service;