<template>
  <v-app style="background-color: #6A887D;">
      <v-card style="margin-top: 10%"
              class="col-6 align-self-center"
              maxWidth="350px"
              maxHeight="400px"
      fluid>
      <v-row>
          <v-col cols="12" sm="4" md="4"/>
          <v-col cols="12" sm="6" md="6" class="px-0 py-0">
            <v-list dense nav>
              <v-list-item>
               
                <v-img
                  :src="logo"
                  contain
                  max-width="90"
                  width="50%"
                  height="50%"
                  class="mr-0 px-0"
                />
                
              </v-list-item>
            </v-list>
          </v-col>
              <v-col cols="12" sm="4" md="4"/> 
         </v-row> 
         <v-row>
           <h5 style="text-align: center; width: 100%; margin-top: -20px;">myBuddy Partner</h5>
         </v-row>
          <v-form
                  class="text-left p-3 align-content-center"
                  ref="form"
                  v-model="valid"
                  lazy-validation
          >
              <v-text-field
                      v-model="user.email"
                      :rules="usernameRules"
                      label="Email"
                      required
                      outlined
                      style="height: 80px;"
              ></v-text-field>

              <v-text-field
                      v-model="user.password"
                      :rules="passwordRules"
                      label="Password"
                      type="password"
                      required
                      outlined
                      style="height: 80px;"
              ></v-text-field>

              <v-btn
                style="width: 100%;" 
                class="mt-4"
                :disabled="!valid"
                color="primary"
                @click="submitForm"
              >
                  Login
              </v-btn>
          </v-form>

      </v-card>
    </v-app>
</template>
<script>
import logo from "../../assets/finalCMYK.png"

import ErrorMessages from './errors'

export default {
  components: {
    ErrorMessages,
  },
  data () {
    return {
      logo: logo,
      valid: true,
      user: {
        email: '',
        password: '',
        remember_me: false
      },
      passwordRules: [
          v => !!v || 'Empty password'
      ],
      usernameRules: [
          v => !!v || 'Empty email',
      ],
    }
  },
  methods: {
    submitForm () {
      // console.log(this.user)
      this.axios.post(window.location.origin + '/panel/auth/', { panel_user: this.user }).then(response => {
        console.log(window.location)
        window.location.pathname = '/panel/admins/'
      }).catch(error => {
        this.errorMessage(-1, 'error user or password', 'error')
      })
    },
    reset () {
      this.$refs.form.reset()
    },
  },
}
</script>
<style scoped>

    .v-application a {
        color: #fff;
    }


</style>
