<template>
  <ul>
    <li
      v-for="(error, index) in errorMessages"
      :key="index"
      class="messages error">
      {{ error.message }}
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      errorMessages: [],
    }
  },
  watch: {
    getAuthErrors: {
      handler (errors) {
        this.parseErrors(errors)
      },
      deep: true,
    },
  },
  methods: {
    parseErrors (errors) {
      this.errorMessages = this.formValidateToArray(errors)
    },
    formValidateToArray (error) {
      if (!error.server) {
        return [].concat.apply([], Object.values(error.errors))
      }
      let message = ''
      try {
        message = JSON.parse(error.errors.request.response).error
      } catch (e) {
        if (error.errors.request.statusText === '') {
          message = 'Network Error'
        } else {
          message = error.errors.request.statusText
        }
      }

      return [{
        message: message,
      }]
    },
  },
}
</script>
