const petMutation = {
  setPets (state, getPet) {
    state.pets = getPet[0]
    state.petsSize = getPet[1]
    state.paginateOptions = getPet[2]
  },
  addPet (state, pet) {
    state.pets = [...state.pets, pet]
  },
  updatePet(state, newPet) {
    state.pets = state.pets.map((pet) => {
      if(pet.id === newPet.id)
        return {...pet, ...newPet}
      return pet
    })
  },
  deletePet(state, deletePet){
    state.pets = state.pets.filter((pet)=>{
      return pet.id !== deletePet;
    })
  },
  setPet (state, getPet) {
    state.pet = getPet
  },
  // pets.forEach((pet)=>{

  // })
  // pets.findIndex()
}

export default petMutation;